import React, { Component, Suspense } from "react"

import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle, Collapse } from "reactstrap"
import { Link, Route, Router, Switch, Redirect } from 'react-router-dom';
import NotFound from "../pages/NotFound"

import Menu from "../components/Menu";
import Home from "./Home"
import MobileNav from "../components/MobileNav";
import Bar from "../components/Bar";
import getMenuItems from "../utils/getMenuItems"
import SendVoucher from "./SendVoucher";
import Analytics from "./Analytics"
import Voucher from "./Voucher"

export default class ContentContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuOpened: false,
            menuItems: [],
        }
    }

    profileMenus = [
        // {
        //     label: 'My Account',
        //     icon: 'mdi mdi-account-circle',
        //     redirectTo: '/account',
        // },
    ];

    updateMenuItems = () => {
        const menuItems = getMenuItems(this.props.auth.user.roles)
        this.setState({ menuItems })
    }

    toggleMenu = (state = null) => {
        // console.log(this.state.isMenuOpened)
        this.setState({ isMenuOpened: state === null ? !this.state.isMenuOpened : state })
    }

    componentDidMount = () => {
        this.updateMenuItems()
    }

    render() {
        const children = this.props.children || null;
        const { user } = this.props.auth
        return (
            <>
                <Container fluid>
                    <Row>
                        <Bar toggleMenu={this.toggleMenu} menuItems={this.profileMenus} user={user.name} {...this.props} />
                    </Row>

                    <Switch>
                        <Route path="/">
                            <Row>
                                <MobileNav
                                    menuItems={this.state.menuItems}
                                    isMenuOpened={this.state.isMenuOpened}
                                    toggleMenu={this.toggleMenu}
                                    updateMenuItems={this.updateMenuItems}
                                />
                            </Row>

                            <Row className="d-sm-block d-xs-block d-md-none">
                                <Col xs={12} sm={12} md={12} style={{ marginTop: 20 }} >
                                    <Menu
                                        menuItems={this.state.menuItems}
                                        updateMenuItems={this.updateMenuItems}
                                        {...this.props}
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginTop: 20 }} >
                                <Col sm={3} md={4} lg={3} xl={2} style={{ height: "100%" }} className="d-none d-md-block">
                                    <Menu
                                        menuItems={this.state.menuItems}
                                        updateMenuItems={this.updateMenuItems}
                                        {...this.props} />
                                </Col>

                                <Col xs={12} sm={12} md={8} lg={9} xl={10}>
                                    <div style={{ height: "100%", minHeight: window.innerHeight }}>
                                        <Switch>
                                            <Route exact {...this.props} path="/">
                                                <Home {...this.props} />
                                            </Route>

                                            <Route exact {...this.props} path="/sendVoucher">
                                                <SendVoucher {...this.props} />
                                            </Route>

                                            <Route exact {...this.props} path="/analytics">
                                                <Analytics {...this.props} />
                                            </Route>

                                            <Route exact {...this.props} path="/voucher">
                                                <Voucher {...this.props} />
                                            </Route>


                                            <Route {...this.props}>
                                                <NotFound />
                                            </Route>
                                        </Switch>
                                    </div>
                                </Col>
                            </Row>
                        </Route>


                    </Switch>


                </Container>

                <footer style={{ textAlign: "center", }}>
                    <hr />
                    <a>{new Date().getFullYear()} © BrandMobile</a>
                    <br /><br />
                    {/* TODO remove */}
                    {/* <div class="alert alert-info">
                        <div class="d-none d-xl-block font-weight-bold">X-LARGE (xl)</div>
                        <div class="d-none d-lg-block d-xl-none font-weight-bold">LARGE (lg)</div>
                        <div class="d-none d-md-block d-lg-none font-weight-bold">MEDIUM (md)</div>
                        <div class="d-none d-sm-block d-md-none font-weight-bold">SMALL (sm)</div>
                        <div class="d-block d-sm-none alert font-weight-bold">X-SMALL (xs)</div>
                    </div> */}
                </footer>

            </>
        )
    }
}