
class Env{
    get DEVELOPER_MODE() {
        return (process.env.NODE_ENV === "development")
    }
}
const env = new Env()

export const DEVELOPER_MODE = env.DEVELOPER_MODE

export default env