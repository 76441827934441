import { getUserLocale } from 'get-user-locale';
import React from "react"
import moment from "moment-timezone"

function replaceWithDots(string) {
    return string.replace(/\//ig, ".")
}

export function epochToLocaleString(epoch) {
    const local = (new Date(epoch).toLocaleDateString())
    return replaceWithDots(local)
}

export function thousandSeperator(number) {
    return number.toLocaleString(getUserLocale())
}

export const dateFormatter = (value) => {
    const mm = moment(value)
    if (mm.isValid()) {
        const date = mm.toDate()
        return  `${replaceWithDots(date.toLocaleDateString())} - ${date.toLocaleTimeString()}`
    } else {
        return null
    }

}

export const tableDateFormatter = (cell, row) => {
    const formatted = dateFormatter(cell)
    if (formatted) {
        return (
            <span>
                {formatted}
            </span>
        )
    } else {
        return null
    }
}


export default {
    epochToLocaleString,
    thousandSeperator,
    tableDateFormatter,
}