
class ImageFacade {

    campaignImage(id) {
        try {
            return require(`../assets/images/dev/campaigns/${id}.png`)
        } catch (e) {
            return require(`../assets/images/dev/campaigns/bm.png`)
        }
    }

    companyImage(id) {
        try {
            return require(`../assets/images/dev/companies/${id}.png`)
        } catch (e) {
            return null
        }
    }

    countryImage(country) {
        try {
            return require(`../assets/images/flags/${country}.png`)
        } catch (e) {
            return null
        }
    }

    userImage(email) {
        try {
            return require(`../assets/images/users/${email}.jpg`)
        } catch (e) {
            return require(`../assets/images/users/user.png`)
        }
    }

}

export default new ImageFacade()