import { noaccess, analytics, voucher, sendVoucher } from "./menuItems"

export default (roles) => {
    let result = []

    if (roles !== undefined) {
        if (roles.length !== 0) {
            for (const access of roles) {
                switch (access) {
                    case "analytics":
                        result.push(
                            analytics,
                        )
                        break
                    case "voucher":
                        result.push(
                            voucher
                        )
                        break
                    case "sendVoucher":
                        result.push(
                            sendVoucher
                        )
                        break
                }
            }
        }
        if (result.length == 0) {
            result.push(
                noaccess
            )
        }


    }

    return result

}