import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import notFoundImg from '../assets/images/sad-bm.png';
import PageTitle from '../hyper_components/PageTitle';
import { makeTo } from '../utils/LinkUtils';

const ErrorPageNotFound = (props) => {
    return (
        <React.Fragment>
            <Row className="justify-content-center" style={{marginTop: 180}} >
                <Col lg={4}>
                    <div className="text-center">
                        <img src={notFoundImg} height="140" alt="" />
                        {/* <h1 className="text-error mt-4">404</h1> */}
                        <h4 className="text-uppercase text-primary mt-3">404 | Page Not Found</h4>
                        <p className="text-muted mt-3">
                            We couldn't find the page that you were looking for. Please return to home.
                        </p>

                        <Link className="btn btn-rounded btn-secondary mt-3" style={{backgroundColor: "#38469b", border: 0}} to={makeTo("/",props.location )}>
                            <i className="mdi mdi-reply"></i> Return Home
                        </Link>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default withRouter(props => <ErrorPageNotFound {...props} />)

