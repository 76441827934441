import React, { Component } from "react"
import Analytics from "./Analytics"
import { Redirect , withRouter} from "react-router-dom"
import { makeTo } from "../utils/LinkUtils";
import getMenuItems from "../utils/getMenuItems"
export default withRouter(props => <Home {...props} />)

 class Home extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { auth } = this.props
        const { roles } = auth.user

        if(!roles){
            return <Redirect to="/logout" />
        }

        return (
            <>
                {
                    roles.length === 0 ?
                        <>
                            <div className="text-center">
                                <h3>No access on project</h3>
                                <h6>Please request access from your super-user to access the project!</h6>
                            </div>
                        </>
                        :
                        <>
                            {
                                roles[0].name === "analytics" ?
                                    <Analytics {...this.props} />
                                    :
                                    <Redirect to={makeTo(getMenuItems(roles)[0].path, this.props.location)} />
                            }
                        </>



                }
            </>
        )
    }
}