import _ from "lodash"
import "url-search-params-polyfill"
import moment from "moment-timezone"

export default function ParamState(propsCb, properties) {
    if (propsCb === undefined) return
    var instance = this
    let URLSP = null


    reset()

    function reset() {
        const props = propsCb()
        URLSP = new URLSearchParams(props.location.search)
    }

    function removeAll() {
        reset()
        for (i in properties) {
            URLSP.delete(i)
        }
        updateURL()
    }

    function remove(name) {
        reset()
        URLSP.delete(name)
        updateURL()
    }

    function updateURL() {
        const props = propsCb()
        const search = URLSP.toString()

        props.history.replace({
            search
        })
    }

    for (var i in properties) {
        (function (i) {
            const setter = function (val) {
                if (val !== undefined && val !== null) {
                    reset()
                    URLSP.set(i, val)
                    updateURL()
                } else {
                    remove(i)
                }
            }

            instance.removeAll = removeAll
            // instance.remove = remove

            instance["get" + _.capitalize(i)] = function () {
                reset()
                let val = URLSP.get(i)
                if (val === null) {
                    val = properties[i]
                    if (val !== null) {
                        setter(val)
                    }
                }
                if (val === null) return null

                //return type conversion
                const valType = typeof properties[i]
                switch (valType) {
                    case "number":
                        val = parseInt(val)
                        break
                    case "object":
                        const date = moment(val).toDate()
                        if(!isNaN(date.getTime())){
                            val = date
                        }
                        break
                }

                return val
            }

            instance["set" + _.capitalize(i)] = setter
        })(i)
    }
}