
export const noaccess = {
    name: "Home",
    path: "/",
    icon: "dripicons-home"
}

export const sendVoucher = {
    name: "Send Voucher",
    path: "/sendVoucher",
    icon: "dripicons-direction"
}

export const voucher = {
    name: "Voucher",
    path: "/voucher",
    icon: "dripicons-ticket"
}

export const analytics = {
    name: "Analytics",
    path: "/analytics",
    icon: " dripicons-graph-line"
}

export default {
    noaccess,
    sendVoucher,
    voucher,
    analytics
}