import React, { Component } from 'react';
import { Spinner } from "reactstrap"

class Loading extends Component {
    render() {
        return (

            <div className="text-center" style={{ marginTop: 50, height: "100%", minHeight: window.innerHeight, padding: 20 }}>
                <Spinner
                    className="text-primary m-2 "
                    size={"lg"}
                    style={{  margin: "auto", height: 100, width: 100 }} />
            </div>
        );
    }
}

export default Loading;