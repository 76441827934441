import utf8 from "utf8"
import {fromByteArray, toByteArray} from "base64-js"

export function encode(obj) {
    return btoa(JSON.stringify(obj))
}

export function decode(string){
    return JSON.parse(atob(string))
}

export default {encode, decode}