import React, { Component, Props } from 'react';
import { Card, CardHeader, Container, Row, Col, CardBody, Alert, Label, FormGroup, Button } from "reactstrap"
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Link, Redirect, withRouter } from "react-router-dom"
import LoaderWidget from '../hyper_components/Loader';
import logo from '../assets/images/logo.png';

import { cold } from 'react-hot-loader';

export default withRouter(props => <Login {...props} />)

class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            redirect: null,
            input: { email: "", password: "" },
            loading: false
        }
    }

    async onValidSubmit(auth) {
        this.setState({ loading: true })
        await auth.authenticate(this.state.input.email, this.state.input.password)

        const { state } = this.props.location
        if (state !== undefined) {
            const { from } = state
            if (from !== undefined) {
                this.setState({ redirect: from })
            }
        }


        this.setState({ redirect: "/", });
    }

    handleKeyPressed = (e) => {
        let input = Object.assign({}, this.state.input)
        input[e.target.name] = e.target.value
        this.setState({ input })
    }

    render() {
        const { auth } = this.props
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Card>
                                    <div className="card-header pt-4 pb-4 text-center bg-primary">
                                        {/* <Link to="/"> */}
                                            <span>
                                                <img src={logo} alt="" height="70" />
                                            </span>
                                        {/* </Link> */}
                                    </div>

                                    <CardBody className="p-4 position-relative">
                                        {/* preloader */}
                                        {this.state.loading && <LoaderWidget />}

                                        <div className="text-center w-75 m-auto">
                                            <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                                                Sign In
                                                    </h4>
                                            <p className="text-muted mb-4">
                                                Enter your email and password to access the dashboard.
                                                    </p>
                                        </div>

                                        {this.props.error && (
                                            <Alert color="danger" isOpen={this.props.error ? true : false}>
                                                <div>{this.props.error}</div>
                                            </Alert>
                                        )}

                                        <AvForm onValidSubmit={() => this.onValidSubmit(auth)}>
                                            <AvField
                                                name="email"
                                                type="email"
                                                label="Email"
                                                placeholder="Enter your email"
                                                value={this.state.input.email}
                                                onChange={this.handleKeyPressed}
                                                required
                                            />

                                            <AvGroup>
                                                <Label for="password">Password</Label>
                                                {/* <a
                                                    href="/forgot-password"
                                                    className="text-muted float-right">
                                                    <small>Forgot your password?</small>
                                                </a> */}
                                                <AvInput
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter your password"
                                                    value={this.state.input.password}
                                                    onChange={this.handleKeyPressed}
                                                    required
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <br />
                                            <FormGroup className="text-center">
                                                <Button color="success">Sign In</Button>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mt-1">
                            <Col className="col-12 text-center">
                                {/* <p className="text-muted">
                                    Don't have an account?{' '}
                                    <Link to="/register" className="text-muted ml-1">
                                        <b>Register</b>
                                    </Link>
                                </p> */}
                            </Col>
                        </Row>

                        <Row className="mt-1">
                            <Col className="col-12 text-center">
                                <p className="text-muted">
                                    {new Date().getFullYear()} © BrandMobile
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>

        )
    }




}


