import React from "react"
import { Route, Redirect, withRouter } from "react-router-dom"


function ProtectedRoute({ children, auth, projectManager, location, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (auth.isAuthenticated()) {
                    return children
                }

                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />

            }}
        />
    );
}

export default withRouter(props => <ProtectedRoute {...props} />)