import FacadeConnection from "./FacadeConnection";

class LoginFacade extends FacadeConnection {
    constructor(){
        super()
    }
    
    async login(email, password) {
        return await this.post("/login", {
            email,
            password
        })
    }
}

export default new LoginFacade()