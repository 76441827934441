
import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Spinner, Badge } from 'reactstrap';
import GenericTable from "../components/GenericTable"
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BASEURL from '../utils/BASEURL';
import { tableDateFormatter } from '../utils/Converter';
import statusCodes from "../statusCodes"


class Voucher extends Component {

    state = {
    }

    dataCb = async (page, show, sortBy = null, order = null, search = null) => {

        const url = `${BASEURL}/voucher?page=${page - 1}&nResults=${show}&sorting=${order}&sortBy=${sortBy}&search=${search}`

        const data = await fetch(url, {
            headers: {
                "sessionID": this.props.auth.sessionID
            }
        })

        return await data.json()
    }

    getUserCount = async (search) => {
        const data = await fetch(BASEURL + "/voucher/count/entries?search=" + search, {
            headers: {
                "sessionID": this.props.auth.sessionID
            }
        })
        return await data.json()
    }
    resendVoucherIsDisabled = (row) => {
        return row.redeemed === null
    }

    reactivateVoucherIsDisabled = (row) => {
        return row.redeemed !== null
    }

    resendVoucher = async (row) => {
        await fetch(BASEURL + "/voucher/resend/" + row.ref, {
            method: "POST",
            headers: {
                "sessionID": this.props.auth.sessionID
            }
        })
    }
    reactivateVoucher = async (row) => {
        await fetch(BASEURL + "/voucher/reactivate/" + row.ref, {
            method: "POST",
            headers: {
                "sessionID": this.props.auth.sessionID
            }
        })
    }

    toggleValid = async (row) => {
        await fetch(BASEURL + "/voucher/toggleValid/" + row.ref, {
            method: "POST",
            headers: {
                "sessionID": this.props.auth.sessionID
            }
        })
    }

    render() {
        
        
        return (
            <div>
                <Card>
                    <CardBody>
                        <h3>Voucher</h3>
                        <GenericTable
                            {...this.props}
                            // hover
                            dataCb={this.dataCb}
                            countCb={this.getUserCount}
                            // rowEvents={{
                            //     onClick: this.handleClick
                            // }}
                            actions={{
                                "Resend Voucher": this.resendVoucher,
                                "Reactivate Voucher": this.reactivateVoucher,
                                "Toggle Valid": this.toggleValid
                            }}
                            actionStates={{
                                "Resend Voucher": this.resendVoucherIsDisabled,
                                "Reactivate Voucher": this.reactivateVoucherIsDisabled,
                                "Toggle Valid": () => true

                            }}
                            columnOptions={{
                                "__v": {
                                    hide: true
                                },
                                "ref": {
                                    text: "ID"
                                },
                                "valid": {
                                    sort: false
                                },
                                "productName": {
                                    sort: false
                                },
                                "created": {
                                    formatter: tableDateFormatter
                                },
                                "expires": {
                                    formatter: tableDateFormatter,
                                },
                                "redeemed": {
                                    formatter: tableDateFormatter,
                                },
                                "productName": {
                                    text: "Product Name"
                                },
                                "storeID": {
                                    text: "Store ID"
                                },
                                "lastDeliverEvent": {
                                    text: "Last Delivery Update",
                                    formatter: tableDateFormatter,
                                },
                                "deliveredStatus": {
                                    text: "Delivery Status",
                                    formatter: (cell, row) => {
                                        if (cell == null) {
                                            return ""
                                        } else {
                                            return <Badge pill color={cell === 1001 ? "success" : "warning"}><a style={{fontSize: 13}}>{statusCodes[cell]}</a></Badge>
                                        }

                                    }
                                },
                                "exportDate":{
                                    text: "Export Date",
                                    formatter: tableDateFormatter

                                },
                                "customerName":{
                                    text: "Customer Name"
                                },
                                "carModel":{
                                    text: "Car Brand"
                                }


                            }}
                            defaultSorted={[{
                                dataField: 'created',
                                order: 'desc'
                            }]}
                        />

                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Voucher