import React, { Component } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle, CardImg, CardTitle, Button, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import MetisMenu from 'metismenujs/dist/metismenujs';
import { makeTo } from "../utils/LinkUtils";


class MobileNav extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { isMenuOpened, menuItems, location } = this.props
        return (
            <Container fluid style={{ padding: 0 }} >
                <div className="topnav d-sm-block d-xs-block d-md-none" style={{ backgroundColor: "linear-gradient(to bottom, #8f75da, #727cf5)" }} >
                    <div className="container-fluid" >
                        <nav className="navbar navbar-dark navbar-expand-lg topnav-menu" >
                            <Collapse isOpen={isMenuOpened} className="navbar-collapse" id="topnav-menu-content" >
                                <ul className="metismenu side-nav" style={{}}>
                                    {menuItems == null ?
                                        <h1>No access</h1>
                                        : menuItems.map(({ name, path, icon }) => {
                                            const { pathname } = this.props.location
                                            const selected = path === pathname
                                            const color = selected ? "white" : "lightgrey"
                                            return (
                                                <Link to={makeTo(path, this.props.location)} onClick={() => this.props.toggleMenu(false)}>
                                                    <li className="side-nav-item" >
                                                        <a className="side-sub-nav-link side-nav-link mm-collapsed" style={{ paddingRight: 0, marginRight: 0 }}>
                                                            <i className={icon} style={{ color }}></i>
                                                            <span style={{ color }}>{name}</span>
                                                        </a>
                                                    </li>
                                                </Link>
                                            )
                                        })
                                    }
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </Container>
        )
    }

}

export default withRouter(props => <MobileNav {...props} />);
