import React, { Component } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col, Dropdown, DropdownMenu, DropdownToggle, CardImg, CardTitle, Button } from "reactstrap"
import { Link, withRouter } from "react-router-dom"


import { makeTo } from "../utils/LinkUtils";


class Menu extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.updateMenuItems()
    }

    render() {
        const { menuItems } = this.props
        return (
            <>
                <div className="d-none d-md-block" >
                    {/* Navigation */}
                    <Card style={{ width: "100%", height: "100%", minHeight: window.innerHeight, marginBottom: 0, maxWidth: 300, minWidth: 160 }}>

                        <CardBody style={{ padding: 0, marginTop: 20 }}>
                            <ul className="metismenu side-nav" style={{ color: "#19191b" }}>
                                <li href="/" className="side-nav-title side-nav-item" style={{ color: "#19191b" }}>
                                    Navigation
                                </li>
                                {
                                    menuItems.map(({ name, path, icon }) => {
                                        const { pathname } = this.props.location
                                        const selected = pathname.indexOf(path) !== -1
                                        const color = selected ? "#38469b" : "#19191b"
                                        return (
                                            <Link to={makeTo(path, this.props.location)}>
                                                <li className="side-nav-item" >
                                                    <a className="side-sub-nav-link side-nav-link mm-collapsed" style={{ paddingRight: 0, marginRight: 0 }}>
                                                        <i className={icon} style={{ color }}></i>
                                                        <span style={{ color }}>{name}</span>
                                                    </a>
                                                </li>
                                            </Link>
                                        )
                                    })
                                }


                            </ul>
                        </CardBody>
                    </Card>
                </div>


            </>
        )
    }
}

export default withRouter(props => <Menu {...props} />);
