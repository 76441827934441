import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom"
import Login from "./pages/Login"
import ContentContainer from "./pages/ContentContainer"
import ProtectedRoute from './components/ProtectedRoute';
import AuthManager from './managers/AuthManager';
import './assets/scss/DefaultTheme.scss';
import Logout from "./pages/Logout"

class App extends Component {
    constructor(props) {
        super(props)
        const { auth } = this.constructManagers()
        this.state = {
            auth,
        }
    }

    constructManagers = () => {
        const auth = new AuthManager()
        return { auth }
    }

    getProps = () => {
        return this.props
    }

    reset = () => {
        const { auth } = this.constructManagers()
        this.setState({ auth })
    }

    render() {
        return (
            <Switch>
                <Route path="/login">
                    <Login auth={this.state.auth} />
                </Route>
                <Route path="/logout" >
                    <Logout auth={this.state.auth} reset={this.reset} />
                </Route>
                <ProtectedRoute auth={this.state.auth} path="/" >
                    <ContentContainer auth={this.state.auth} data={this.state.data} />
                </ProtectedRoute>
            </Switch>
        );
    }

}

export default withRouter(props => <App {...props} />)

