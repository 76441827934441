import React from "react"
import { Redirect, withRouter } from "react-router-dom"
import { makeTo } from "../utils/LinkUtils"

export default withRouter(props => <Logout {...props} />)

function Logout({ location, auth, reset }) {
    auth.logout()
    reset()
    return <Redirect to={{
        pathname: "/login"
    }} />
}