import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardHeader, CardImg } from 'reactstrap';
import BASEURL from '../utils/BASEURL';
import { thousandSeperator } from '../utils/Converter';

class Analytics extends Component {

    state = {
        products: []
    }

    async componentDidMount() {
        let products =  this.props.auth.user.products
        this.setState({ products })

    }

    render() {

        let sent = 0, redeemed = 0, total = 0

        this.state.products.map(p => {
            total += p.total
            sent += p.used
            redeemed += p.redeemed
        })

        let available = total - sent

        return (
            <div>
                <h3>Analytics</h3>
                <br />
                <Row className="text-center">
                    <Col>
                        <Card className="mt-2" style={{ maxWidth: 400, margin: "auto" }}>
                            <CardBody>
                                <h1>{thousandSeperator(sent)}</h1>
                                <br />
                                <h4>Vouchers Created</h4>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="mt-2" style={{ maxWidth: 400, margin: "auto" }}>
                            <CardBody>
                                <h1>{thousandSeperator(redeemed)}</h1>
                                <br />
                                <h4>Vouchers Redeemed</h4>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="mt-2" style={{ maxWidth: 400, margin: "auto" }}>
                            <CardBody>
                                <h1>{thousandSeperator(available)}</h1>
                                <br />
                                <h4>Vouchers Available</h4>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row className="p-0">
                    {this.state.products.map(product => {
                        return (
                            <Col sm={12} lg={6} xl={6} >
                                <span className="m-2">
                                    <Card style={{ margin: "auto", maxWidth: 700 }}>
                                        <CardImg top height={150} style={{ objectFit: "cover" }} src={require(`../assets/images/products/${product.imgDash}`)} />
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <h3>{product.name}</h3>
                                                    <h6>{product.brand}</h6>

                                                </Col>

                                            </Row>
                                            <hr />
                                            <Row className="text-center">
                                                <Col>
                                                    <Card className="mt-2" style={{ maxWidth: 400, margin: "auto" }}>
                                                        <CardBody>
                                                            <h3>{thousandSeperator(product.redeemed)}</h3>
                                                            <br />
                                                            <h5>Vouchers Redeemed</h5>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card className="mt-2" style={{ maxWidth: 400, margin: "auto" }}>
                                                        <CardBody>
                                                            <h3>{thousandSeperator(product.total - product.used)}/{thousandSeperator(product.total)}</h3>
                                                            <br />
                                                            <h5>Vouchers Available</h5>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                            </Row>




                                        </CardBody>
                                    </Card>
                                </span>

                            </Col>

                        )

                    })}
                </Row>


            </div>
        );
    }
}

export default Analytics;