import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from '../components/Loading';
import { Container, CardBody, Card, Row, Col, Spinner, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ParamState from "../utils/ParamState";
import { thousandSeperator as ts } from "../utils/Converter";
import _ from "lodash"
import { withRouter } from 'react-router-dom';
import "url-search-params-polyfill"
import overlayFactory from 'react-bootstrap-table2-overlay';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class GenericTable extends Component {

    constructor(props) {
        super(props)

        this.ps = new ParamState(() => this.props, {
            page: 1,
            orderby: null,
            order: null,
            show: 10,
            search: null,

        })

        this.state = {
            tableData: [],

            totalSize: 0,

            loading: false,
        }
    }

    fetchData = async () => {
        this.setState({ loading: true })
        const order = this.ps.getOrder()
        const orderby = this.ps.getOrderby()
        const show = this.ps.getShow()
        const search = this.ps.getSearch()

        const page = this.ps.getPage()

        const tableData = await this.props.dataCb(page, show, orderby, order, search)
        this.setState({ tableData, loading: false })
    }

    updateCount = async () => {
        const { data } = this.props
        const totalSize = await this.props.countCb(this.ps.getSearch())

        await this.setState({ totalSize })
    }

    componentDidMount = async () => {
        if (this.isRemote) {
            await this.updateCount()
        }
        if (this.isRemote) {
            await this.fetchData()
        }
    }

    componentWillUnmount = async () => {
        //TODO remove table specific url params
        this.ps.removeAll()
    }

    handleTableChange = async (type, { page, sizePerPage, filters, sortField, sortOrder, searchText, cellEdit }) => {
        if (type == "search") {
            this.ps.setSearch(searchText)

            await this.setState({ loading: true })
            if (this.ps.getSearch() !== searchText) return

            this.ps.setPage(1)

            await this.updateCount()

        } else {
            await this.setState({ loading: true })

            this.ps.setPage(page)
            this.ps.setOrder(sortOrder)
            this.ps.setOrderby(sortField)
            this.ps.setShow(sizePerPage)
            this.ps.setSearch(searchText)


        }
        await this.fetchData()
        this.setState({ loading: false })
    }

    offlinePaginationFactory = () => {
        if (this.props.pagination !== undefined) {
            return paginationFactory({
                sizePerPage: 5,
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '25', value: 25
                },
                {
                    text: '50', value: 50
                }
                ]
            }
            )
        } else {
            return null
        }
    }

    actionFormatter = (cell, row) => {
        const { actions, actionStates } = this.props
        return (
            <UncontrolledDropdown direction="down">
                <DropdownToggle tag="text" style={{ cursor: 'pointer' }}>
                    <i className="mdi mdi-dots-vertical mt-0"></i>
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(actions).map((key) => {
                        let disabled = false
                        if (actionStates) {
                            disabled = !actionStates[key](row)
                        }
                        return <DropdownItem disabled={disabled} onClick={async () => {
                            await actions[key](row)
                            await this.fetchData()
                        }}>{key}</DropdownItem>
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>

        )
    }

    generateCols = () => {
        const first = this.tableData[0]
        if (!first) return [{
            dataField: "nodata",
            text: "No data",
            sort: false
        }]
        let result = []
        const { columnOptions, rowEvents, actions } = this.props
        
        const allKeys = {}
        for (const tableRow of this.tableData) {
            for (const key in tableRow) {
                allKeys[key] = null
            }
        }

        for (const key of Object.keys(allKeys)) {
            if (columnOptions) {
                if (columnOptions.hasOwnProperty(key)) {
                    const opt = columnOptions[key]
                    const { hide, formatter, } = opt
                    const sort = opt.sort !== false
                    const text = opt.text ? opt.text : _.capitalize(key)
                    const colOpt = {
                        hidden: hide === true,
                        dataField: key,
                        text,
                        sort,
                    }

                    if (formatter) {
                        colOpt.formatter = formatter
                    }
                    result.push(colOpt)

                    continue

                }
            }
            result.push({
                dataField: key,
                text: _.capitalize(key),
                sort: true // default
            })
        }


        if (rowEvents) {
            result.forEach((row) => {
                row.events = rowEvents
            })
        }

        if (actions) {
            result.push({
                sort: false,
                formatter: this.actionFormatter
            })
        }

        return result

    }

    get isRemote() {
        return this.props.dataCb !== undefined
    }

    get tableData() {
        const _tableData = this.isRemote ? this.state.tableData : this.props.tableData
        const { columnOptions } = this.props

        if (_tableData.length > 0) {
            for (const columnOption in columnOptions) {
                if (columnOptions.hasOwnProperty(columnOption)) {
                    const options = columnOptions[columnOption];
                    const { converter } = options
                    if (converter) {
                        _tableData.forEach(row => {
                            row[columnOption] = converter(row[columnOption])
                        })
                    }
                }
            }
        }

        return _tableData
    }

    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const columns = this.generateCols()

        if (this.isRemote) {
            var page = this.ps.getPage()
            var search = this.ps.getSearch()
        }


        return (
            <ToolkitProvider
                {...this.props}

                bootstrap4
                keyField="user_id"
                data={this.tableData}
                search={this.isRemote}
                hover={this.props.hover}
            // defaultSorted={this.props.defaultSorted}
            >
                {props => (
                    <React.Fragment>
                        <Row>
                            <Col>
                                {this.state.loading &&
                                    <Spinner
                                        className="text-primary m-2"
                                        size={"lg"}
                                        style={{ margin: "auto", height: 15, width: 15 }} />
                                }
                            </Col>
                            {this.isRemote &&
                                <Row className="text-right">
                                    <Col xs={10}>
                                        <Row>
                                            <Col xs={search ? 9 : 12}>
                                                <SearchBar
                                                    delay={1000}
                                                    placeholder={`Search ${search ? `(${search})` : ''}`}
                                                    className="float-right" {...props.searchProps}
                                                    clearSearchBtn={() => console.log('clear')}

                                                />
                                            </Col>
                                            {search &&
                                                <Col xs={3} onClick={() => {
                                                    setTimeout(() => {
                                                        this.ps.setSearch(null)
                                                        if (!this.state.loading) {
                                                            this.fetchData()
                                                        }
                                                    }, 1)
                                                }}>
                                                    <ClearSearchButton className="btn btn-secondary" {...props.searchProps} />
                                                </Col>
                                            }

                                        </Row>






                                    </Col>
                                </Row>
                            }

                        </Row>

                        <BootstrapTable
                            {...props.baseProps}
                            striped
                            remote={this.isRemote}
                            hover={this.props.hover}
                            bordered={false}
                            columns={columns}
                            onTableChange={this.handleTableChange}

                            // options={options}
                            defaultSorted={this.props.defaultSorted}
                            pagination={this.isRemote ? paginationFactory({
                                page,
                                showTotal: true,
                                totalSize: this.state.totalSize,
                                paginationTotalRenderer: (from, to, size) => (
                                    <span className="react-bootstrap-table-pagination-total ml-2 mt-1">
                                        Showing {ts(from)} to {ts(to)} of {ts(size)}
                                    </span>
                                ),
                                sizePerPage: this.ps.getShow(),
                                sizePerPageList: [{
                                    text: '10', value: 10
                                }, {
                                    text: '25', value: 25
                                }, {
                                    text: '50', value: 50
                                },
                                ],

                            }) : this.offlinePaginationFactory()}

                            wrapperClasses="table-responsive"
                        />
                    </React.Fragment>
                )
                }
            </ToolkitProvider>

        );
    }
}
export default withRouter(props => <GenericTable {...props} />)
