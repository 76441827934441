import BASEURL from "../utils/BASEURL";

let URI = BASEURL
// URI = "https://7fb83bd3.ngrok.io"
// console.log(this.location.href)

export default class FacadeConnection {
    _controller
    _signal

    constructor() {
        this._controller = new AbortController();
        this._signal = this._controller.signal;
    }

    async _send(path, method = "GET", params = null, sessionID = null, body = null,  test) {
        // console.log(test)
        
        // Creating url
        const urlObj = new URL(URI + path)

        // Setting params on url
        if (params !== null) {
            for (const key in params) {
                urlObj.searchParams.append(key, params[key])
            }
        }

        // Defining options object
        const options = {
            method,
            headers: {
                "Session-Id": sessionID,
                "Content-Type": "application/json",
            },
            signal: this.signal
        }

        // Parsing body
        if (body !== null) {
            options.body = JSON.stringify(body)
        }

        return await fetch(urlObj, options)
    }

    async abort() {
        this._controller.abort()
    }

    async get(path, multi = null, params = []) {
        if (typeof multi === "string") {
            // if sessionID
            return await (await this._send(path, "GET", null, multi)).json()
        } else {
            // if datamanager
            const { authManager } = multi
            const { sessionID } = authManager

            return await (await this._send(path, "GET", params, sessionID)).json()
        }
    }

    async post(path, body, sessionID = null) {
        return await (await this._send(path, "POST", null, sessionID, body)).json()
    }

    async put(path, body = null, sessionID = null, projectID = null) {
        return await (await this._send(path, "PUT", null, sessionID, projectID, body)).json()
    }

    async delete(path, multi = null, projectID = null, params = []) {
        if (typeof multi === "string") {
            // if sessionID
            return await (await this._send(path, "DELETE", null, multi, projectID)).json()
        } else {
            // if datamanager
            const { authManager, projectManager, all, start, end } = multi
            projectID = projectManager.project.id
            const { sessionID } = authManager
            const params = {
                all,
                start,
                end
            }

            return await (await this._send(path, "DELETE", params, sessionID, projectID))
        }
    }
}

